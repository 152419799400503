.tos__header {
  height: 15rem;
  border-radius: 8px;
  background-color: #f6f6fa;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 88%;
  margin: 0 auto;
  margin-top: 4rem;

  @media (max-width: 768px) {
    height: 13rem;
    width: 100%;
  }
}

.tos__main {
  font-size: 1.8rem;
  padding-bottom: 16rem;

  p {
    line-height: 3rem;
    margin-bottom: 1rem;
  }

  h4 {
    font-family: SF Pro Text Bold;
    margin-top: 3rem;
  }

  strong {
    font-family: SF Pro Text Bold;
  }

  @media (max-width: 769px) {
    font-size: 0.8rem;
    padding-bottom: 8rem;

    p {
      line-height: 2rem;
      margin-bottom: 2rem;
    }

    h4 {
      margin-top: 6rem;
    }
  }
}

.tos__outline {
  margin-bottom: 5rem;

  @media (max-width: 768px) {
    margin-bottom: 2rem;
  }
}