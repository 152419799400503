h1 {
  @media (max-width: 768px) {
    font-size: 1.8rem;
  }
}

h2 {
  font-size: 2.5rem;
  font-family: SF Pro Text Regular;
  line-height: 1.2;

  @media (max-width: 768px) {
    font-size: 1.8rem;
  }
}

h3 {
  font-size: 2.5rem;
  line-height: 1.2;

  // @media (max-width: 768px) {
  //   font-size: 1.8rem;
  // }
}

@font-face {
  font-family: "SF Pro Text Regular";
  src: url("../../src/assets/fonts/SFProTextRegular.woff2");
}

@font-face {
  font-family: "SF Pro Text Bold";
  src: url("../../src/assets/fonts/SFProTextBold.woff2");
}

@font-face {
  font-family: "SF Pro Text Light";
  src: url("../../src/assets/fonts/SFProTextLight.woff2");
}

@font-face {
  font-family: "SF Pro Text Medium";
  src: url("../../src/assets/fonts/SFProTextMedium.woff2");
}

@font-face {
  font-family: "SF Pro Text Semibold";
  src: url("../../src/assets/fonts/SFProTextSemibold.woff2");
}

.text--dark-blue {
  color: var(--dark-blue);
}

.text--bold {
  font-family: SF Pro Text Semibold;
}

.text--center {
  text-align: center;
}

.text--no-underline {
  text-decoration: none;
}

.text--light-grey {
  color: #828282;
}

.text--dark-grey {
  color: #333333;
}

.text-center--mb {
  @media (max-width: 768px) {
    text-align: center;
  }
}