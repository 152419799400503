::placeholder {
  color: #c4c4c4;
}

.form-control {
  width: 100%;
}

.form-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.form-column {
  flex: 1;

  @media (max-width: 768px) {
    flex-basis: 100%;
  }
}

.form-input {
  padding: 1.4rem 2rem;
  font-size: 2.4rem;
  border: 1px solid #c7c7cc;
  background: linear-gradient(180deg, #ffffff 50%, #f2f2f2 100%), #ffffff;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
    0px 4px 8px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  display: flex;
  flex-direction: column;

  span {
    text-transform: uppercase;
    font-size: 1.4rem;
    color: #828282;

    @media (max-width: 768px) {
      font-size: 1rem;
    }
  }

  @media (max-width: 768px) {
    padding: 0.8rem 1rem;
    font-size: 1.2rem;
  }
}

.form-input__body {
  display: flex;
  padding: 1rem 0;

  @media (max-width: 768px) {
    padding: 0.5rem 0;
  }
}

.form-input__select {
  border: 0;
  font-size: inherit;
  appearance: none;
  background: transparent;
  outline: none;
  color: var(--body-color);
  // color: #c4c4c4;
  text-indent: 8px;
  flex-grow: 1;

  padding-right: 4rem;
  background-image: url("../../src/assets/svg/chevron.svg");
  background-repeat: no-repeat;
  background-position-x: calc(100% - 1rem);
  background-position-y: 50%;
  background-size: 2rem;

  @media (max-width: 768px) {
    background-size: 1rem;
  }
}

.form-input__icon {
  @media (max-width: 768px) {
    height: 1.2rem;
    width: auto;
  }
}

.form-input__icon--location {
  content: url("../../src/assets/svg/location.svg");
}

.form-input__icon--truck {
  content: url("../../src/assets/svg/truck.svg");
}

.form-input__icon--nearby {
  content: url("../../src/assets/svg/nearby.svg");
}

.form-input__icon--clock {
  content: url("../../src/assets/svg/clock.svg");
}

.form-input__icon--calendar {
  content: url("../../src/assets/svg/calendar.svg");
}

.form-input__icon--weight {
  content: url("../../src/assets/svg/weight.svg");
}
