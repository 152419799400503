.signup__page {
  background: var(--signup-grey);
  // height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.signup__svg {
  width: 18rem;
  padding: 10rem 0;
  height: auto;

  @media (max-width: 768px) {
    width: 8rem;

    padding: 6rem 0;
  }
}

.signup__form {
  width: 40%;
  background: rgb(255, 255, 255);
  padding: 4rem 9rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-bottom: 5rem;

  h3,
  p {
    text-align: center;
  }

  h3 {
    margin-bottom: 1rem;
  }

  p {
    margin-bottom: 2rem;
    font-size: 1.8rem;
  }

  label {
    color: #c4c4c4;
    font-size: 1.8rem;
  }

  .signup__select-type {
    color: var(--body-color);

    input {
      margin-right: 0.5rem;
    }
  }

  .form-input {
    margin-bottom: 2rem;
    font-size: 1.6rem;
  }

  @media (max-width: 768px) {
    width: 85%;
    padding: 4rem;

    .form-input {
      font-size: 1.2rem;
      margin-bottom: 1rem;
    }

    h3,
    p {
      font-size: 1.2rem;
    }

    p,
    label,
    small {
      font-size: 1.2rem;
    }
  }
}

.signup__terms-agreement {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;

  input {
    margin-right: 1rem;
  }
}

.signup__register-link {
  margin-top: 1rem;

  span {
    cursor: pointer;
    color: rgb(238, 202, 48);
    text-decoration: none;
    font-weight: bold;
  }
}