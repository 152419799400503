.landing-page__header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  background-image: url("../../src/assets/images/hero_image.gif");
  background-repeat: no-repeat;
  background-position: center right;
  background-size: 700px;
  transform: translateY(-9rem);
  padding-left: 10rem;

  @media (max-width: 768px) {
    padding-left: 2rem;
    transform: translateY(0);
    height: 19rem;
    background: #f6f6fa;
  }
}

.jumbotron__image {
  display: none;

  @media (max-width: 768px) {
    display: block;
    background-image: url("../../src/assets/images/hero_image.gif");
    background-position: center center;
    background-size: cover;
    border-radius: 5px;
    height: 19rem;
    width: 85%;
    margin: 2.4rem auto 4rem;
  }
}

.landing-page__main {
  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
}

.landing-page__blockquote {
  font-size: 2.8rem;

  @media (max-width: 768px) {
    font-size: 1.8rem;
  }
}

.landing-page__1 {
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    margin-bottom: 3rem;
    text-align: center;
  }
}

.landing-page__2 {
  margin-bottom: 4rem;

  @media (max-width: 768px) {
    margin-bottom: 3rem;
    text-align: center;
  }

  img {
    @media (max-width: 768px) {
      margin-bottom: 4rem;
    }
  }

  .left {
    padding-top: 6rem;
    padding-right: 5rem;

    h2,
    p,
    button {
      margin-bottom: 3rem;
    }

    @media (max-width: 768px) {
      padding: 0;

      h2,
      p,
      button {
        margin-bottom: 2.5rem;
      }
    }
  }
}

.landing-page__3 {
  margin-bottom: 20rem;

  @media (max-width: 768px) {
    margin-bottom: 3rem;
    text-align: center;
  }

  img {
    @media (max-width: 768px) {
      margin-bottom: 4rem;
    }
  }

  .right {
    padding-top: 6rem;
    padding-left: 5rem;

    h2,
    p,
    button {
      margin-bottom: 3rem;
    }

    @media (max-width: 768px) {
      padding: 0;

      h2,
      p,
      button {
        margin-bottom: 2.5rem;
      }
    }
  }
}

// .hero__title {
//   font-size: 7rem;
//   color: var(--dark-blue);
//   line-height: 8rem;
// }

.landing__title {
  font-size: 4rem;
  color: var(--dark-blue);
  line-height: 6rem;
  padding-bottom: 2rem;

  @media (max-width: 768px) {
    padding-bottom: 1.5rem;
    font-size: 2.4rem;
    line-height: 2.9rem;
  }
}

.landing__subtitle {
  font-size: 1.8rem;
  padding-bottom: 3rem;
  width: 44rem;

  @media (max-width: 768px) {
    padding-bottom: 1.5rem;
    font-size: 10px;
    line-height: 12px;
    width: 24rem;
  }
}

.hero__cta {
  align-self: flex-start;
  display: inline;
  background: var(--dark-green);
  border-radius: var(--border-radius);
  padding: 1.8rem 2.8rem;
  color: var(--white-white);
  text-decoration: none;
  font-size: 2.4rem;

  @media (max-width: 768px) {
    font-size: 1.2rem;
    padding: 0.8rem 2.1rem;
  }
}

.hiw-section {
  background: #f6f6fa;
  padding-bottom: 30rem;
  padding-top: 15rem;

  @media (max-width: 768px) {
    padding-top: 4.5rem;
    padding-bottom: 3rem;
  }
}

.hiw-section__heading {
  grid-area: heading;
  margin-bottom: 1.5rem;

  @media (max-width: 768px) {
    text-align: center;
    font-size: 1.8rem;
  }
}

.hiw-section__p {
  margin-bottom: 5rem;
  font-size: 2rem;

  @media (max-width: 768px) {
    margin-bottom: 2rem;
  }
}

.hiw-section__1 {
  padding-top: 10rem;
  margin-bottom: 20rem;

  display: grid;
  grid-template-areas:
    "heading image"
    "checkpoints image";
  grid-template-columns: 50% 50%;
  flex-direction: row;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    padding-top: 5rem;
    margin-bottom: 0;

    grid-template-areas:
      "heading heading"
      "checkpoints image";
  }

  .hiw-section__heading {
    padding-right: 10rem;

    @media (max-width: 768px) {
      padding: 0;
    }
  }

  .left {
    grid-area: checkpoints;
    padding-right: 10rem;
    flex: 1;

    @media (max-width: 768px) {
      padding: 0;
    }
  }

  .right {
    grid-area: image;
    flex: 1;
  }
}

.hiw-section__2 {
  margin-bottom: 1rem;
  display: grid;
  grid-template-areas:
    "image heading"
    "image checkpoints";
  grid-template-columns: 50% 50%;

  @media (max-width: 768px) {
    padding-top: 5rem;
    margin-bottom: 0.2rem;

    grid-template-areas:
      "heading heading"
      "image checkpoints";
  }

  .hiw-section__heading {
    padding-left: 10rem;

    @media (max-width: 768px) {
      padding: 0;
    }
  }

  .left {
    grid-area: image;
    flex: 1;

    @media (max-width: 768px) {
      padding: 0;
    }
  }

  .right {
    grid-area: checkpoints;
    padding-left: 10rem;

    @media (max-width: 768px) {
      padding: 0;
    }
  }
}

.hiw-section__3 {
  @media (max-width: 768px) {
    text-align: center;
    margin-bottom: 4rem;
  }

  .hiw-section__image {
    display: block;
    width: 80%;
    margin-left: auto;

    @media (max-width: 768px) {
      margin-top: 2rem;
      display: inline-block;
      width: 100%;
    }
  }
}

.hiw-section__image {
  @media (max-width: 768px) {
    height: 20rem;
    border-radius: 8px;
    object-fit: cover;
    object-position: 50% 30%;
  }
}