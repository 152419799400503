.mv-gds-grid {
  display: grid;
  grid-template-areas:
    "main image"
    "download download";
  grid-template-columns: 50% 1fr;
  padding-top: 8rem;
  padding-bottom: 20rem;

  @media (max-width: 768px) {
    grid-template-areas:
      "main"
      "image"
      "download";
    grid-template-columns: 100%;
    padding-bottom: 9rem;
  }
}

.mv-gds-main {
  grid-area: main;
  font-size: 1.8rem;
  padding-left: 10rem;
  padding-right: 4rem;

  @media (max-width: 768px) {
    padding: 0 5rem;
    margin-bottom: 6rem;

    .hero__title {
      text-align: center;
    }

    .hero__subtitle {
      text-align: center;
    }
  }
}

.mv-gds-image {
  grid-area: image;
  padding-right: 10rem;

  @media (max-width: 768px) {
    padding-right: 0;
    margin-bottom: 5rem;
  }
}

.download-app {
  grid-area: download;
  padding-left: 10rem;
  margin-top: 5rem;
  width: 50%;

  @media (max-width: 768px) {
    text-align: center;
    padding: 0 5rem;
    width: 100%;
  }
}

.download-app__heading {
  margin-bottom: 1.5rem;

  @media (max-width: 768px) {
    font-size: 2.4rem;
  }
}

.download-app__copy {
  @media (max-width: 768px) {
    font-size: 1rem;
    line-height: 1.2rem;
  }
}

.download-app__metrics {
  display: flex;
  align-items: center;
  margin: 3rem 0;

  @media (max-width: 768px) {
    justify-content: center;
  }
}

.download-app__total-downloads {
  &:after {
    content: "";
    display: inline-block;
    vertical-align: middle;
    background: url("../../src/assets/svg/download-multiple.svg") no-repeat;
    width: 20px;
    height: 20px;
    margin: 0 0 4px 6px;
  }

  @media (max-width: 768px) {
    font-size: 1.6rem;
  }
}

.download-app__rating {
  margin-right: 2rem;

  @media (max-width: 768px) {
    width: 37%;
    max-width: 12rem;
    height: auto;
  }
}

.hero__talking-points {
  list-style: none;

  @media (max-width: 768px) {
    font-size: 0.2rem;
  }
}

.hero__talking-point {
  padding: 2.4rem 0;
  border-bottom: solid 1px var(--border-grey);
  display: flex;
  align-items: flex-start;

  span {
    padding-left: 1.6rem;
  }

  @media (max-width: 768px) {
    padding: 1rem 0;
    border: none;

    img {
      width: 2rem;
      height: auto;
    }

    span {
      padding-left: 1rem;
      line-height: 1.2rem;
    }
  }
}

.hero__talking-point:first-child {
  border-top: solid 1px var(--border-grey);

  @media (max-width: 768px) {
    border: none;
  }
}

.preview__image-bg {
  height: 56rem;
  width: 53rem;
  background-color: var(--hawkes-blue);
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;

  @media (max-width: 768px) {
    height: 35rem;
    width: 90%;
  }
}

.preview__image {
  transform: translateY(2rem);
  height: 125%;
  width: auto;
}