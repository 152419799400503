.request-quote__header {
  width: 85%;
  margin: 6rem auto 20rem;
  display: grid;
  grid-template-columns: 50% 1fr;
  grid-template-rows: 50rem 1fr;
  grid-template-areas:
    "copy form"
    "... form";

  @media (max-width: 768px) {
    width: 100%;
    margin: 2rem auto 5rem;
    grid-template-columns: 100%;
    grid-template-rows: 15rem 1fr;
    grid-template-areas:
      "copy"
      "form";
  }
}

.request-quote__header-copy {
  background: #f6f6fa;
  border-radius: 8px;
  grid-area: copy-start / copy-start / copy-end / form-end; // grid-row-start, grid-column-start, grid-row-end and grid-column-end
  padding: 10rem 50% 4rem 5rem;

  @media (max-width: 768px) {
    border-radius: 0;
    text-align: center;
    padding: 2rem 10%;
  }
}

.request-quote__form {
  padding-top: 10rem;
  padding-right: 5rem;
  grid-area: form;

  .form-input {
    margin-bottom: 2rem;
  }

  .form-column:first-child {
    margin-right: 2rem;
  }

  @media (max-width: 768px) {
    padding: 5rem 5rem 0;

    .form-column:first-child {
      margin-right: 0;
    }
  }
}

.request-quote__1 {
  display: grid;
  grid-template-areas:
    "image heading"
    "image copy";
  grid-template-columns: 60% 1fr;
  grid-template-rows: fit-content(100%) 1fr;
  margin-bottom: 20rem;

  @media (max-width: 768px) {
    text-align: center;
    grid-template-areas:
      "heading"
      "image"
      "copy";
    grid-template-columns: 100%;
    grid-template-rows: 4rem 1fr;
    margin-bottom: 4rem;

    p {
      font-size: 0.8rem;
    }
  }
}

.request-quote__1__image {
  width: 80%;
  margin: 0 auto;
  grid-area: image;
}

.request-quote__1__heading {
  grid-area: heading;
}

.request-quote__1__copy {
  grid-area: copy;

  @media (max-width: 768px) {
    padding: 2rem 3rem;
  }
}

.faq-section {
  background: #faf7f9;
  font-size: 2rem;
  line-height: 1.6;
  padding-top: 20rem;
  padding-bottom: 30rem;

  h4 {
    margin-bottom: 1.6rem;
  }

  @media (max-width: 768px) {
    font-size: 0.8rem;
    text-align: center;
    padding-top: 3rem;
    padding-bottom: 5rem;

    h4 {
      font-size: 1.2rem;
    }
  }
}

.faq-section__faqs {
  display: flex;
  flex-wrap: wrap;
  padding-left: 10rem;

  @media (max-width: 768px) {
    flex-direction: column;
    padding-left: 0;
  }
}

.faq-section__faq {
  display: flex;
  flex-direction: column;
  flex-basis: 28%;
  margin-right: 5%;
  margin-bottom: 5rem;

  @media (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 2rem;
  }
}

.faq-section__heading {
  padding-bottom: 6rem;
  width: 40%;

  @media (max-width: 768px) {
    padding-bottom: 4rem;
    width: 60%;
    text-align: left;
  }
}