.dashed-lines {
  border-top: 2px dashed #c4c4c4;

  margin-top: 4rem;
  margin-bottom: 2rem;

  @media (max-width: 768px) {
    border-top: 1px dashed #c4c4c4;

    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }
}

/* Hero */

.hero__title {
  color: var(--dark-blue);
}

.hero__subtitle {
  font-size: 2.8rem;

  @media (max-width: 768px) {
    font-size: 1rem;
    // line-height: 12px;
  }
}

.hero__img {
  width: 100%;
  height: 60rem;
  object-fit: cover;
  border-radius: 8px;
  box-shadow: 0px 9px 10px rgba(12, 55, 125, 0.1);

  @media (max-width: 768px) {
    height: 20rem;
    border-radius: 2px;
  }
}

.img--full-width {
  width: 100%;
  height: auto;
}

.img--box-shadow {
  box-shadow: 0px 9px 10px rgba(12, 55, 125, 0.1);
}

.border-radius--8 {
  border-radius: 8px;
}

/* Talking points */

.check-points {
  list-style: none;

  @media (max-width: 768px) {
    font-size: 0.8rem;
    line-height: 1rem;
  }
}

.check-point {
  margin: 2.4rem 0;
  display: flex;
  align-items: flex-start;

  span {
    padding-left: 1.6rem;
  }

  @media (max-width: 768px) {
    margin: 1rem 0;
  }
}

.check-point__image {
  @media (max-width: 768px) {
    height: 1rem;
    width: auto;
  }
}

.check-point__content {
  display: flex;
  flex-direction: column;
  margin-left: 1.6rem;

  @media (max-width: 768px) {
    margin-left: 0.4rem;
  }
}

.check-point__text {
  font-size: 1.8rem;

  @media (max-width: 768px) {
    font-size: 0.8rem;
    line-height: 1rem;
  }
}

.check-point__heading {
  line-height: 3rem;

  @media (max-width: 768px) {
    line-height: 1rem;
  }
}

/* Statistics */

.statistics {
  position: absolute;
  width: 55rem;
  height: 55rem;
  background-color: #fff;
  padding: 5rem 3rem;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04),
    0px 16px 24px rgba(0, 0, 0, 0.04), 0px 24px 32px rgba(0, 0, 0, 0.04);
  z-index: 50;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  @media (max-width: 768px) {
    height: 22rem;
    width: 22rem;
    padding: 1rem;
  }

  h2 {
    color: #333333;

    @media (max-width: 768px) {
      font-size: 1.2rem;
    }
  }

  p {
    margin-top: 2rem;
    margin-bottom: 4rem;

    @media (max-width: 768px) {
      font-size: 1rem;
      margin-top: 0.5rem;
      margin-bottom: 1rem;
    }
  }
}

.statistics__number {
  font-size: 3rem;
  line-height: 3rem;
  font-family: SF Pro Text Bold;
  color: #828282;

  @media (max-width: 768px) {
    font-size: 1rem;
    line-height: 1rem;
  }
}

.statistics__stats {
  display: flex;
  flex-wrap: wrap;
}

.statistics__stat {
  display: flex;
  align-items: flex-start;
  margin-right: 3rem;
  margin-bottom: 3rem;

  @media (max-width: 768px) {
    margin-right: 1rem;
    margin-bottom: 1rem;
  }

  .stat__image {
    @media (max-width: 768px) {
      width: 1.5rem;
    }
  }

  .stat__text {
    padding-left: 1.5rem;
    display: flex;
    flex-direction: column;

    @media (max-width: 768px) {
      padding-left: 0.5rem;
    }
  }

  .statistics__item {
    font-size: 18px;
    font-family: SF Pro Text Regular;

    @media (max-width: 768px) {
      font-size: 0.8rem;
    }
  }
}

/* Ship track */

.ship-track {
  display: flex;
  align-items: center;
  height: 56rem;
  background-color: var(--light-blue);
  border-radius: 10px;
  background-image: url("../../src/assets/images/phones.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left center;
  width: 85%;
  margin: 0 auto;

  @media (max-width: 768px) {
    height: 15rem;
    width: 100%;
    border-radius: 0px;
  }
}

.ship-track__spacing {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-right: 4rem;
  padding-left: 55%;

  @media (max-width: 768px) {
    padding-right: 1.5rem;
  }
}

.ship-track__heading {
  padding-bottom: 2rem;

  @media (max-width: 768px) {
    font-size: 0.8rem;
    padding-bottom: 0.5rem;
  }
}

.ship-track__p {
  @media (max-width: 768px) {
    font-size: 0.6rem;
    line-height: 1.2rem;
  }
}

.ship-track__cta {
  margin-bottom: 1rem;
  @media (max-width: 768px) {
    font-size: 0.6rem;
  }
}

/* Press kit */
.press-kit {
  border: 2px dashed #c4c4c4;
}

.press-kit__download {
  color: #828282;
  text-decoration: none;
  font-family: SF Pro Text Semibold;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
}

/* Download app */

.download-app__links {
  display: flex;

  @media (max-width: 768px) {
    justify-content: center;
  }
}

.download-app__link {
  height: 5.5rem;
  width: auto;

  &:first-child {
    margin-right: 3rem;
  }

  @media (max-width: 768px) {
    width: calc(50% - 0.25rem);
    max-width: 15rem;
    height: auto;

    &:first-child {
      margin-right: 1rem;
    }
  }
}

/* Sections */
.bg--blue {
  background: #bed1ef;
}
