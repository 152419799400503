.footer {
  font-size: 1.6rem;

  @media (max-width: 768px) {
    font-size: 0.6rem;
  }
}

.early-access {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: var(--mustard);
  height: 37.5rem;

  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.early-access__heading {
  font-size: 4rem;

  @media (max-width: 768px) {
    font-size: 1.8rem;
  }
}

.early-access__image {
  border-radius: var(--border-radius);
  height: 28rem;
  width: auto;

  @media (max-width: 768px) {
    height: auto;
    width: 80%;
  }
}

.early-access__copy {
  display: flex;
  flex-direction: column;
  font-size: 1.8rem;
  width: 43rem;

  @media (max-width: 768px) {
    text-align: center;
    align-items: center;
    font-size: 1.2rem;
    width: 100%;
  }
}

.early-access__p {
  width: 85%;
}

.early-access__label {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
  height: 6rem;
  background-color: #fff;
  border-radius: var(--border-radius);

  @media (max-width: 768px) {
    height: 4rem;
    width: 70%;
    margin: 0 auto;
  }
}

.early-access__input {
  font-size: inherit;
  border: 0;
  padding: 1rem;
  flex-grow: 1;

  &::placeholder {
    color: var(--dark-blue);
  }

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
}

.early-access__button {
  font-size: inherit;
  background-color: var(--body-color);
  border: 0;
  border-radius: 2px;
  color: #fff;
  padding: 1rem;

  @media (max-width: 768px) {
    font-size: 1.2rem;
    padding: 0.5rem;
  }
}

.footer__title {
  text-transform: uppercase;
  color: var(--white-white);

  margin-bottom: 2.5rem;

  @media (max-width: 768px) {
    margin-bottom: 0.5rem;
  }
}

.footer__links {
  list-style: none;

  li {
    margin-bottom: 1.6rem;
    display: flex;
    align-items: center;

    @media (max-width: 768px) {
      margin-bottom: 0.5rem;
    }
  }
}

.footer__link {
  color: #000;
  text-decoration: none;

  &:hover {
    color: var(--dark-blue);
    transition: all 0.3s;
  }
}

.footer__grid {
  display: grid;
  grid-template-areas: "branding menu partners tos contact";
  grid-template-columns: 30% 1fr 1fr 1fr 1fr;
  padding: 5rem 10rem;
  background-color: var(--dark-green);
  color: #000;

  @media (max-width: 768px) {
    grid-template-columns: 30% 1fr 1fr 1fr;
    grid-template-areas:
      "branding branding menu partners"
      "contact contact tos tos";
    padding: 3rem 1.6rem;
  }
}

.footer__branding {
  grid-area: branding;
  display: flex;
  flex-direction: column;
  padding-right: 10rem;

  @media (max-width: 768px) {
    padding-right: 3rem;
  }

  svg {
    height: 5rem;
    width: 9.8rem;

    @media (max-width: 768px) {
      width: 5rem;
      height: 2rem;
    }
  }
}

.footer__menu {
  grid-area: menu;
  display: flex;
  flex-direction: column;
}

.footer__partners {
  grid-area: partners;
  display: flex;
  flex-direction: column;
}

.footer__tos {
  grid-area: tos;
  display: flex;
  flex-direction: column;
}

.footer__contact {
  grid-area: contact;
  display: flex;
  flex-direction: column;
}

.footer__phone {
  width: 2.2rem;
  height: 1.2rem;

  @media (max-width: 768px) {
    display: none;
  }
}

.footer__mail {
  width: 2.2rem;
  height: 1.2rem;

  @media (max-width: 768px) {
    padding-left: 0;

    display: none;
  }
}

.footer__socials {
  width: 80%;
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    width: 40%;
  }
}

.footer__social-icon {
  @media (max-width: 768px) {
    width: 1rem;
    height: auto;
  }
}

.footer__copyright {
  display: flex;
  align-items: center;
  height: 3rem;
  background-color: #0AA89A;
  color: #000;
  padding-left: 10rem;

  @media (max-width: 768px) {
    justify-content: center;
    height: 4rem;
    padding-left: 0;
  }
}