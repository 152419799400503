$sizes: (1rem: 1rem,
  2rem: 2rem,
  3rem: 3rem,
  4rem: 4rem,
  6rem: 6rem,
  5rem: 5rem,
  8rem: 8rem,
  10rem: 10rem,
  15rem: 15rem,
  20rem: 20rem,
  30rem: 30rem,
  50rem: 50rem,
);

@mixin space($property: "property", $position: "position") {

  @each $size,
  $value in $sizes {
    &-#{$size} {
      #{$property}-#{$position}: $value;
    }
  }
}

/* Spacing */

.mb {
  @include space($property: "margin", $position: "bottom");
}

.mr {
  @include space($property: "margin", $position: "right");
}

.ml {
  @include space($property: "margin", $position: "left");
}

.mt {
  @include space($property: "margin", $position: "top");
}

.pl {
  @include space($property: "padding", $position: "left");
}

.pr {
  @include space($property: "padding", $position: "right");
}

.pb {
  @include space($property: "padding", $position: "bottom");
}

.pt {
  @include space($property: "padding", $position: "top");
}

/* Wrapper */
.wrapper {
  width: 85%;
  margin: 0 auto;
}

/* Flex */
.flex-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.flex-column {
  flex-basis: 100%;

  @media (min-width: 768px) {
    flex: 1;
  }
}

/*  Flex width */
@media screen and (min-width: 768px) {
  ._20 {
    flex: 2;
  }

  ._25 {
    flex: 2.5;
  }

  ._40 {
    flex: 4;
  }

  ._45 {
    flex: 4.5;
  }

  ._50 {
    flex: 5;
  }

  ._55 {
    flex: 5.5;
  }

  ._60 {
    flex: 6;
  }
}