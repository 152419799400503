.navigation--desktop {
  position:relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 100;
  width: 88%;
  height: 9rem;
  margin: 0 auto;
  color: var(--muted-color);
  font-size: 1.6rem;
  font-family: SF Pro Text Regular;

  @media (max-width: 768px) {
    display: none;
  }
}

.navigation--mobile {
  position: relative;
  display: none;

  @media (max-width: 768px) {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 100;
    width: 88%;
    height: 9rem;
    margin: 0 auto;
    color: var(--muted-color);
    font-size: 1.6rem;
    font-family: SF Pro Text Regular;
  }
}

.navigation--mobile__menu {
  display: none;

  @media (max-width: 768px) {
    visibility: hidden;
    font-size: 1.2rem;
    position: absolute;
    border: 1px solid #c7c7cc;
    border-radius: 5px;
    background: white;
    padding: 1.6rem 3rem;
    right: 0;
    top: 7rem;
    display: flex;
    flex-direction: column;

    a {
      margin-bottom: 1.2rem;
      text-decoration: none;
      color: var(--body-color);
    }
  }
}

.navigation--mobile__menu--active {
  visibility: visible;
}

.navigation__logo {
  margin-right: 5rem;

  svg {
    height: 3rem;
    width: auto;

    @media (max-width: 768px) {
      height: 4rem;
      width: auto;
    }
  }

  @media (max-width: 768px) {
    margin-right: 0;
  }
}

.navigation__items {
  list-style: none;
  display: flex;
}

.navigation__group {
  display: flex;
  align-items: center;
}

.navigation__item {
  a {
    color: var(--muted-color);
    text-decoration: none;
  }
}

.navigation__cta {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.navigation__sign-up {
  background-color: var(--mustard);
  padding: 0.8rem 1.5rem;
  border-radius: var(--border-radius);
  color: var(--body-color);

  a {
    color: var(--body-color);
    text-decoration: none;
  }

  @media (max-width: 768px) {
    font-size: 1.2rem;
    padding: 0.8rem 2rem;
    justify-self: center;
  }
}

.download-link__icon::after {
  content: "";
  display: inline-block;
  vertical-align: middle;
  background: url("../../src/assets/svg/chevron.svg") no-repeat;
  width: 6px;
  height: px;
  margin: 2px 0 0 6px;
}