.for-business__header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 6rem;
  padding-bottom: 20rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-left: 10rem;
  padding-right: 10rem;

  @media (max-width: 768px) {
    padding: 0;
    transform: translateY(0);
    // height: 19rem;
  }
}

.for-business__form {
  display: flex;

  @media (max-width: 768px) {
    flex-direction: column;

    button {
      width: fit-content;
      margin: 2rem auto 0;
    }
  }
}

.for-business__form-input {
  flex-grow: 1;
  border: 0;
  background: none;
  font-size: inherit;
  font-family: SF Pro Text Light;
}

.for-business__header-col {
  flex-basis: 100%;

  .hero__title {
    padding-bottom: 3rem;
  }

  .hero__subtitle {
    padding-bottom: 3rem;
  }

  .form-input {
    margin-right: 2rem;
    flex-grow: 1;

    @media (max-width: 768px) {
      margin-right: 0;
    }
  }

  @media (min-width: 768px) {
    flex: 1;
  }
}

.for-business__header-col--1 {
  padding-top: 2rem;
  padding-right: 5rem;

  @media (max-width: 768px) {
    text-align: center;
    padding: 3.5rem 3rem;
    background: #f6f6fa;
  }
}

.for-business__header-col--2 {
  padding: 2rem;
}

.for-business__1 {
  display: grid;
  grid-template-areas:
    "image heading"
    "image copy";
  grid-template-columns: 60% 1fr;
  grid-template-rows: fit-content(100%) 1fr;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    text-align: center;
    grid-template-areas:
      "heading"
      "image"
      "copy";
    grid-template-columns: 100%;
    margin-bottom: 4rem;

    p {
      font-size: 0.8rem;
    }
  }
}

.for-business__1__image {
  width: 80%;
  grid-area: image;

  @media (max-width: 768px) {
    margin: 0 auto;
  }
}

.for-business__1__heading {
  grid-area: heading;

  @media (max-width: 768px) {
    margin-bottom: 3rem;
  }
}

.for-business__1__copy {
  grid-area: copy;

  @media (max-width: 768px) {
    padding: 2rem 3rem;
  }
}

.for-business__1__cta {
  @media (max-width: 768px) {
    font-size: 1.6rem;
  }
}

.for-business__2 {
  display: grid;
  grid-template-areas:
    "heading image"
    "copy image";
  grid-template-columns: 1fr 50%;
  grid-template-rows: fit-content(100%) 1fr;
  margin-bottom: 20rem;

  @media (max-width: 768px) {
    text-align: center;
    grid-template-areas:
      "heading"
      "image"
      "copy";
    grid-template-columns: 100%;
    margin-bottom: 4rem;

    p {
      font-size: 0.8rem;
    }
  }
}

.for-business__2__image {
  width: 100%;
  grid-area: image;

  @media (max-width: 768px) {
    width: 80%;
    margin: 0 auto;
  }
}

.for-business__2__heading {
  width: 60%;
  grid-area: heading;

  @media (max-width: 768px) {
    font-size: 1.8rem;
    margin-bottom: 3rem;
    width: 100%;
  }
}

.for-business__2__copy {
  grid-area: copy;
  padding-right: 12rem;

  @media (max-width: 768px) {
    padding: 2rem 3rem;
  }
}

.business-perks {
  grid-area: image;
  width: 80%;
  margin: 0 auto;
  grid-area: image;

  position: relative;
  margin: 0 auto;
  width: 52rem;
  width: 84.97%;
  height: 68rem;
  background: rgb(211, 248, 232, 0.5);
}

.business-perks__image {
  position: absolute;
  width: 49rem;
  width: 94.23%;
  height: 70rem;
  bottom: 7rem;
  height: 100%;
  border-radius: 4px;
  z-index: 10;
}

.business-perks__card {
  position: absolute;
  font-size: 2rem;
  display: flex;
  flex-direction: column;
  align-content: space-around;
  top: 9rem;
  left: -10rem;
  background: #fff;
  width: 40rem;
  height: 56rem;
  z-index: 20;
  padding: 4rem;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04),
    0px 16px 24px rgba(0, 0, 0, 0.04), 0px 24px 32px rgba(0, 0, 0, 0.04);

  svg {
    width: 100%;
  }

  h4 {
    font-size: 2.4rem;
  }
}

.business-perks__sale {
  margin: 1.5rem 0;
  padding: 1.5rem 0;
  border-top: 2px solid #000;
  border-bottom: 2px solid #000;
}

.bg-beige {
  position: relative;
  background: #f9f8f2;
  border-radius: 10px;
  height: 85rem;
  width: 85%;
  margin: 20rem auto;

  @media (max-width: 768px) {
    height: 45rem;
    width: 100%;
    margin: 8rem auto;
  }
}

.bg-pink {
  position: relative;
  background: #e6b5c2;
  border-radius: 10px;
  height: 55rem;
  margin: 35rem auto 20rem;
  width: 85%;

  @media (max-width: 768px) {
    height: 15rem;
    width: 100%;
    margin: 4rem auto 3rem;
    border-radius: 0;
  }
}

.bg-pink__image {
  position: absolute;
  left: 1rem;
  bottom: 0;
  width: 35%;
  height: auto;
}

.bg-pink__text {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateY(-50%);
  width: 40%;
  height: auto;

  @media (max-width: 768px) {
    font-size: 0.8rem;
  }
}

.bg-pink__heading {
  @media (max-width: 768px) {
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }
}

.bg-pink__p {
  @media (max-width: 768px) {
    font-size: 0.8rem;
    margin-bottom: 1rem;
  }
}

.statistics__position--fb {
  top: 50%;
  right: 5%;
  transform: translateY(-50%);

  @media (max-width: 768px) {
    top: 5rem;
    left: 50%;
    transform: translate(-50%, 60%);
  }
}

.statistics__image {
  height: 55%;
  width: auto;
  position: absolute;
  left: 5%;
  top: 50%;
  transform: translateY(-45%);
  border-radius: 8px;

  @media (max-width: 768px) {
    left: 50%;
    transform: translate(-50%, 0);
    top: 5rem;

    border-radius: 0;
    height: auto;
    width: 85%;
    max-width: 36rem;
  }
}