.btn {
  border: 0;
  font-size: 2.4rem;
  padding: 1.4rem 4rem;
  border-radius: var(--border-radius);
  color: var(--body-color);

  @media (max-width: 768px) {
    font-size: 1.2rem;
    padding: 0.8rem 2.1rem;
  }
}

.btn--primary {
  background-color: var(--mustard);
}
