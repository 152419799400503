* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  /* Colors */
  --body-color: #222222;
  --muted-color: #4f4f4f;
  --light-grey: #c4c4c4;
  --dark-grey: #bdbdbd;
  --border-grey: #e1e1e1;
  --signup-grey: #f1f1f1;
  --mustard: #eeca30;
  --border-radius: 4px;
  --dark-blue: #0c377d;
  --dark-green: #0AA89A;
  --white-white: #ffffff;
  --light-blue: #bed1ef;
  --hawkes-blue: #e2ebfb;
}

html {
  font-size: 62.5%;
  height: 100%;
}

body {
  font-size: 24px;
  font-family: SF Pro Text Light;
  font-size: 2.4rem;
  line-height: 1.5;
  line-height: 1.833;
}

[v-cloak] {
  display: none;
}