.post-signup__header {
  margin-top: 15rem;
  @media (max-width: 768px) {
    margin-top: 0;
    padding: 4rem;

    p {
      font-size: 1rem;
    }
  }
}
