.about-page__header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 6rem;
  padding-bottom: 20rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-left: 10rem;
  padding-right: 10rem;

  @media (max-width: 768px) {
    padding: 0;
    transform: translateY(0);
    // height: 19rem;
  }
}

.about-page__header-col {
  flex-basis: 100%;

  @media (min-width: 768px) {
    flex: 1;
  }
}

.about-page__header-col--1 {
  padding-top: 2rem;
  padding-right: 5rem;

  @media (max-width: 768px) {
    text-align: center;
    padding: 3.5rem 3rem;
    background: #f6f6fa;
  }
}

.about-page__header-col--2 {
  padding: 2rem;
}

.about-page__main {
  @media (max-width: 768px) {
    font-size: 0.8rem;
  }
}

.about-page__1 {
  margin-bottom: 10rem;

  @media (max-width: 768px) {
    margin-bottom: 8rem;
    text-align: center;
  }
}

.about-page__2 {
  margin-bottom: 20rem;

  @media (max-width: 768px) {
    margin-bottom: 4rem;
    text-align: center;
  }
}

.about-page__3 {
  margin-bottom: 20rem;

  @media (max-width: 768px) {
    margin-bottom: 4rem;
    text-align: center;
  }
}

.hero__title--about-page {
  padding-bottom: 3rem;
}

.statistics__section {
  position: relative;
  height: 85rem;
  background-image: url("../../src/assets/images/chat.png");
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 10px;

  @media (max-width: 768px) {
    height: 30rem;
  }
}

.statistics__position--au {
  top: 50%;
  right: 5%;

  @media (max-width: 768px) {
    top: 0;
    left: 50%;
    transform: translate(-50%, 60%);
  }
}

.center-align {
  align-items: center;
}

.blockquote {
  border-left: 4px solid #a7c7f9;
  padding-left: 1.6rem;
  text-align: left;
  width: fit-content;

  @media (max-width: 768px) {
    margin: 0 auto;
    margin-bottom: 2rem;
    padding-left: 0.8rem;
  }
}

.about__image {
  width: 80%;
  height: auto;
  border-radius: 8px;

  @media (max-width: 768px) {
    margin-top: 2rem;
    margin-bottom: 2rem;
    width: 100%;
  }
}

.about__image--ml {
  margin-left: 20%;

  @media (max-width: 768px) {
    margin-left: 0;
  }
}

.about__image--mr {
  margin-right: 20%;

  @media (max-width: 768px) {
    margin-right: 0;
  }
}

.companies-section {
  background-color: #faf7f9;
  margin-bottom: 2rem;
  padding-top: 20rem;

  @media (max-width: 768px) {
    margin-bottom: 5rem;
    padding-top: 4rem;
  }
}

.companies-section__heading {
  width: 85%;
  margin: 0 auto;
  padding-bottom: 6rem;

  h2 {
    width: 50%;
    color: #333333;
  }

  p {
    width: 60%;

    @media (max-width: 768px) {
      width: 100%;

      font-size: 0.8rem;
    }
  }

  @media (max-width: 768px) {
    padding-bottom: 4rem;
    text-align: center;

    h2 {
      width: 100%;
    }

    p {
      width: 100%;
      font-size: 0.8rem;
    }
  }
}

.companies-section__companies {
  display: flex;
  justify-content: space-between;
  width: 85%;
  margin: 0 auto;
  padding-bottom: 20rem;

  @media (max-width: 768px) {
    padding-bottom: 4rem;
  }

  img {
    @media (max-width: 768px) {
      width: 3.5rem;
      height: auto;
    }
  }
}